// ***********************************************
// Settings
// ***********************************************

// Full width Page (full width regions, just the .inner containers get a max-width and will be centered)
$full_width_page: false;
// Touch Menu Opener Label anzeigen "Menu"
$show_touch_menu_label: false;
// Footer Menu Vertikal oder Horizontal darstellen
$show_footer_menu_vertical: true;
// Footer as Mini Panel
$footer_mini_panel: false;
// Dock Form Actions (Save, Delete, etc.)
$dock_form_actions: false;
// Navigation Bar has a search-block inside it? (Headroom.js)
$headroom_searchblock: true;
// Tile Grid
$layout_tile_grid_active: false;
// EU Cookie Compliance Message
// -> See global.objects.scss - you have to manually comment this thing in/out till we´re able to use @import inside @if $eu_cookie_compliance_active: true;

// ***********************************************
// Typography
// These font stacks are an exact copy of the stacks found in AT Cores
// theme settings for web safe fonts.
// ***********************************************

$font_first: 'Open Sans', Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$font_second: 'Crimson Text', Georgia, serif;
$font_code: Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif;

$iconfont: 'iconfont';
$icon_size_xs: 12px;
$icon_size_normal: 18px;
$icon_size_xl: 32px;
$icon_size_xxl: 46px;
$icon_size_xxxl: 56px;

$font_size: 100%;  // Basic font size (for Body)
$font_size_px: 16px;
$font_normal: $font_size_px; // 14 Pixel (for reset purpose)
$font_small: 12px;
$font_small_px: $font_small;
$font_medium_px: 26px;
$font_medium: $font_medium_px;
$font_large: 35px;
$font_large_px: $font_large;
$font_weight_thin:300;
$font_weight_normal: 400;
$font_weight_bold: 600;

$font_second_size_px: 18px;
$font_second_weight_normal: 400;

$line_height: 1.571em;
$line_height_medium: 1.35em;
$line_height_smedium: 1.1em;
$line_height_small: 1em; // Line height for $font_small

$paragraph_spacing: $line_height * 0.6;

// Headings
$h1_font_size: 32px;
$h1_line_height: $line_height_medium;
$h1_font_weight: $font_weight_normal;
$h1_font_family: $font_second;

$h2_font_size: 26px;
$h2_line_height: $line_height_smedium;
$h2_font_weight: $font_weight_normal;
$h2_font_family: $font_second;

$h3_font_size: 22px;
$h3_line_height: $line_height_small;
$h3_font_weight: $font_weight_normal;
$h3_font_family: $font_second;

$h4_font_size: $font_size_px;
$h4_line_height: $line_height_medium;
$h4_font_weight: $font_weight_normal;
$h4_font_family: $font_second;

$h5_font_size: 0.83em;
$h5_line_height: $line_height_medium;
$h5_font_weight: $font_weight_bold;
$h5_font_family: $font_first;

$h6_font_size: 0.75em;
$h6_line_height: $line_height_medium;
$h6_font_weight: $font_weight_bold;
$h6_font_family: $font_first;

// ***********************************************
// Color Variables
// Colors used in the sample global.styles. You can use these to recolor many
// elements such as forms, tables, tabs etc.
// ***********************************************

// webks
$webks_grey: #454545;
$webks_grey_light: #E2E2E2;
$webks_blue: #009CFF;

// Colors
$white: #fff;
$black: #000;

$highlight: #0e4dac;    // Primary Company CD color
$highlight_light: lighten($highlight, 3%);
$highlight_lighter: lighten($highlight, 35%);
$highlight_dark: #074093;
$highlight_sec: #d02b2b;    // Secondary Company CD color
$highlight_sec_dark: #b12727;
$highlight_sec_light: lighten($highlight_sec, 3%);
$black_glass: rgba(0,0,0,0.85);
$black_glass_light: rgba(0,0,0,0.55);
$white_glass: rgba(255,255,255,0.85);
$white_glass_light: rgba(255,255,255,0.65);

$debug:         rgba(255,255,0,0.5);

// Basic Greytone
$grey: #959ba4;
$grey_light: #ebebeb;
$grey_dark: #3f3f3f;

$bluish_grey: #d0d0d0;
$bluish_grey_light: #a2a8b0;
$bluish_grey_lighter: #e4e7eb;
$bluish_grey_lightest: #F2F2F3;
$bluish_grey_dark: #959ba4;

// Basic Green (for add buttons)
$green: #bcc826;
$green_light: #c7d32c;
$green_dark: darken($green, 3%);

// Basic Red (for remove buttons)
$red: $highlight_sec;
$red_light: $highlight_sec_light;
$red_dark: $highlight_sec_dark;

// Background Colors
$body_background: #f4f4f4;
$page_background: white;
$highlight_bg_color: $highlight;
$full_width_bg_color: $grey_light;
$area_hover_bg_color: lighten($bluish_grey_light, 30%);

// Text Colors
$text_color:     #484848;
$title_color: $grey_dark;
$highlight_text_color: #fff; // Text color on highlight_bg

// (Text) Selection Color
$selection_color: $highlight;
$selection_color_foreground: $highlight_text_color;

// Link Colors
$link_color: $text_color;
$link_color_hover: $highlight; // hover
$link_color_active: $link_color_hover; // active
$link_color_focus: $link_color_hover; // focus
$link_color_visited: $link_color; // visited
$highlight_link_color: $highlight; // Link color on highlight_bg

// Menus
$menu_flyout_background: white;
$menu_flyout_color: $text_color;
$menu_offcanvas_background: $highlight;
$menu_offcanvas_background__depth_1: darken($highlight, 6%);
$menu_offcanvas_background__depth_2: darken($highlight, 8%);
$menu_offcanvas_background__depth_3: darken($highlight, 12%);
$menu_offcanvas_color: $highlight_text_color;

// Page Element Colors
$border_color: $grey;    // all borders set in global.styles - tables, fieldset, primary links
$image_background_color: $grey_light;

// Social Media Colors
$facebook_color: #3b5998;
$pinterest_color: #cb2027;
$gplus_color: #dd4b39;

// Shadows
$shadow_intensity: rgba(0,0,0,0.4);
$shadow: 5px 7px 1px -2px $shadow_intensity;
$shadow_small: 0 5px 3px -2px $shadow_intensity;
$shadow_flyout:0px 8px 7px -2px $shadow_intensity;

// Images
$image_background_color: $grey_light;

// Forms
$tabledrag_icon_width: 41px;
$image_preview_width: 110px; // preview image on image fields

$form_description_color: $grey;
$form_item_border: $bluish_grey;
$form_item_border_hover: $bluish_grey_dark;
$form_item_border_active: $highlight;


// ***********************************************
// Metrics
// ***********************************************
$border_radius: 8px;
$border_radius_small: 5px;
$border_radius_smaller: 3px; // just for eg. images inside a thumb wrapper which has radius small

// ***********************************************
// Messages (Warnings etc.)
// ***********************************************
$message_border_radius:$border_radius_small;

$message_info_text:#31708f;
$message_info_background:#d9edf7;
$message_info_border:#bce8f1;

$message_success_text:#3c763d;
$message_success_background:#dff0d8;
$message_success_border:#d6e9c6;

$message_warning_text:#8a6d3b;
$message_warning_background:#fcf8e3;
$message_warning_border:#faebcc;

$message_error_text:#a94442;
$message_error_background:#f2dede;
$message_error_border:#ebccd1;

// Deprecated
$info_color:  $message_info_border;
$info_text_color: $message_info_text;


// ***********************************************
// jQuery.UI
// ***********************************************
$jq_image_folder: "images/jquery.ui/";

$jq_border: $grey;
$jq_border_radius: $border_radius_small;
$jq_background: white;
$jq_color: $text_color;

$jq_highlight_background: $highlight;
$jq_highlight_border: $highlight;
$jq_highlight_color: white;

$jq_hover_background: $highlight;
$jq_hover_border: $highlight;
$jq_hover_color: white;

$jq_active_background: $highlight;
$jq_active_border: $highlight;
$jq_active_color: white;

$jq_error_background: $red_light;
$jq_error_border: $red;
$jq_error_color: $red;

$jq_header_background: $grey_light;

// ***********************************************
// Object Configuration
// ***********************************************

// Mobile Menu Bar
$mobile_menu_bar_height: 42px;

// Inputs
$input_default_v_space: 12px;
$input_default_h_space: 12px;
$input_default_font_size: $font_size_px;
$input_default_line_height: $input_default_font_size + 2px;

// Buttons
$btn_radius: $border_radius_small;

// -- Defaults
$btn_default_v_space: $input_default_v_space;
$btn_default_h_space: $input_default_h_space;
$btn_default_line_height: $input_default_line_height;
$btn_default_font_size: $input_default_font_size;
$btn_default_border_color: $highlight;
$btn_default_bg_color: $highlight;
$btn_default_text_color: $highlight_text_color;

// -- Sizes
$btn_xs_v_space: ceil($input_default_v_space * 0.5);
$btn_xs_h_space: ceil($input_default_h_space * 0.5);
$btn_xs_font_size: $font_small_px;
$btn_xs_line_height: 1em;

$btn_xl_v_space: $input_default_v_space * 1.25;
$btn_xl_h_space: $input_default_h_space * 1.25;
$btn_xxl_v_space: $input_default_v_space * 1.75;
$btn_xxl_h_space: $input_default_h_space * 1.25;
$btn_xxxl_v_space: $input_default_v_space * 2.25;
$btn_xxxl_h_space: $input_default_h_space * 2.25;

// -- Ghost
$btn_ghost_border_color: $form_item_border;
$btn_ghost_bg_color: transparent;
$btn_ghost_text_color: $text_color;

// -- Ghost Dark (on dark backgrounds)
$btn_ghost_dark_border_color: $highlight_dark;
$btn_ghost_dark_bg_color: transparent;
$btn_ghost_dark_text_color: $highlight_text_color;
