// ***********************************************
// Buttons
// ***********************************************

@mixin btn($color_scheme: 'default', $size: 'default', $icon: null, $icon_only: 'false'){
  border-width:1px;
  border-style:solid;
  display: inline-block;
  font-size: $font_normal;
  font-weight: $font_weight_normal;
  line-height: 1.05em;
  cursor:pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select:none;

  // Color
  @include btn-mod($color_scheme);
    
  // Size
  @include btn-size($size);

  // Icon Only (remove text, show only the icon)
  @if($icon_only != 'false'){
    text-indent:-9000px;
    position:relative;
    padding:0;
    min-width:$icon_size_normal + ($btn_default_h_space * 2);
    line-height:$icon_size_normal + ($btn_default_v_space * 2);
  }

  @if($icon_only != 'false' OR $icon != null){
    &::before{
      // Icon
      @if($icon_only != 'false'){
        text-indent:0;
        float:left;
        vertical-align:middle;
        width:100%;
        text-align:center;
      }else{
        line-height: 1;
      }
      @if($icon != null){
        display: inline-block;
        font-family: $iconfont;
        font-style: normal;
        font-weight: normal;
        margin-right:5px;
        text-align:center;
        content: map-get($icon_list, $icon);
      }
    }
  }
  &:hover{
    text-decoration:none;
  }
}

// Button Modifier
// -- Size
@mixin btn-size($size){
  @if($size == xs){
    padding:$btn_xs_v_space $btn_xs_h_space;
    border-radius:$btn_radius;
    line-height:$btn_xs_line_height;
    font-size:$btn_xs_font_size;
  }@else if( $size == xl){
    padding:$btn_xl_v_space $btn_xl_h_space;
    border-radius:$btn_radius;
    line-height:$btn_default_line_height;
    font-size:$btn_default_font_size;
  }@else if( $size == xxl){
    padding:$btn_xxl_v_space $btn_xxl_h_space;
    border-radius:$btn_radius;
    line-height:$btn_default_line_height;
    font-size:$btn_default_font_size;
  }@else if( $size == xxxl){
    padding:$btn_xxxl_v_space $btn_xxxl_h_space;
    border-radius:$btn_radius;
    line-height:$btn_default_line_height;
    font-size:$btn_default_font_size;
  }@else{
    // Default
    padding:$btn_default_v_space $btn_default_h_space;
    border-radius:$btn_radius;
    line-height:$btn_default_line_height;
    font-size:$btn_default_font_size;
  }
}

// -- Style
@mixin btn-mod($color_scheme, $actions_only: 'false'){
  // Adds only the new colors, not the entire button code
  // $actions_only: For buttons they show onother color scheme on :hover & :active
  // TODO: actions_only may not the best solution, maybe we simply have to define seperate color schemes for buttons like eg. 'normal-to-red'
  @if($color_scheme == 'red'){
    @if($actions_only == 'false'){
      background-color:$red !important;
      border-color:$red !important;
      text-shadow:0 1px 1px lighten($red, 12%);
      color:white !important;
    }
    &:hover{
      background-color: lighten($red, 3%) !important;
      border-color: lighten($red, 3%) !important;
      color:white !important;
    }
    &:active,
    &:focus{
      background-color:darken($red, 3%) !important;
      border-color:darken($red, 3%) !important;
      color:darken($red, 20%) !important;
    }
  }@else if( $color_scheme == 'green'){
    @if($actions_only == 'false'){
      background-color:$green !important;
      border-color:$green !important;
      color:white !important;
    }
    &:hover{
      background-color: lighten($green, 3%) !important;
      border-color: lighten($green, 3%) !important;
      color:white !important;
    }
    &:active,
    &:focus{
      background-color: darken($green, 3%) !important;
      border-color: darken($green, 3%) !important;
      color:darken($green, 20%) !important;
    }
  }@else if( $color_scheme == 'grey'){
    @if($actions_only == 'false'){
      background-color: darken($grey_light, 3%) !important;
      border-color: darken($grey_light, 3%) !important;
      color:$text_color !important;
    }
    &:hover{
      background-color: $highlight !important;
      border-color: $highlight !important;
      color:$highlight_text_color !important;
    }
    &:active,
    &:focus{
      background-color: darken($grey_light, 3%) !important;
      border-color: darken($grey_light, 3%) !important;
      color:darken($grey, 20%) !important;
    }
  }@else if( $color_scheme == 'disabled'){
    @if($actions_only == 'false'){
      background-color: darken($grey_light, 3%) !important;
      border-color: $grey !important;
      color:darken($grey, 15%) !important;
    }
    &:hover{
      background-color: darken($grey_light, 5%) !important;
      border-color: $grey !important;
      color:darken($grey, 15%) !important;
    }
    &:active,
    &:focus{
      background-color:darken($red, 3%) !important;
      border-color:darken($red, 3%) !important;
      color:darken($red, 20%) !important;
    }
  }@else if( $color_scheme == 'ghost'){
    @if($actions_only == 'false'){
      background-color:$btn_ghost_bg_color !important;
      border-color:$btn_ghost_border_color !important;
      color:$btn_ghost_text_color !important;
    }
    &:hover{
      background-color: $btn_ghost_bg_color !important;
      border-color:lighten($btn_ghost_border_color, 8%) !important;
      color:$highlight !important;
    }
    &:active,
    &:focus{
      background-color:$btn_ghost_bg_color !important;
      border-color:darken($btn_ghost_border_color, 5%) !important;
      color:$btn_ghost_text_color !important;
    }
  }@else if( $color_scheme == 'ghost-dark'){
    @if($actions_only == 'false'){
      background-color:$btn_ghost_dark_bg_color !important;
      border-color:$btn_ghost_dark_border_color !important;
      color:$btn_ghost_dark_text_color !important;
    }
    &:hover{
      background-color: $btn_ghost_dark_bg_color !important;
      border-color:lighten($btn_ghost_dark_border_color, 5%) !important;
      color:$btn_ghost_dark_text_color !important;
    }
    &:active,
    &:focus{
      background-color:$btn_ghost_dark_bg_color !important;
      border-color:darken($btn_ghost_dark_border_color, 5%) !important;
      color:$btn_ghost_dark_text_color !important;
    }
  }@else if( $color_scheme == 'highlight'){
    @if($actions_only == 'false'){
      // Active (default button active state)
      border-color:$highlight !important;
      background-color:$highlight !important;
      color: $highlight_text_color !important;
    }
    &:hover{
      border-color:lighten($highlight, 5%) !important;
      background-color:lighten($highlight, 5%) !important;
      color: $highlight_text_color !important;
    }
    &:active,
    &:focus{
      border-color:darken($highlight, 5%) !important;
      background-color:darken($highlight, 5%) !important;
      color: $highlight_text_color !important;
    }
  }@else if( $color_scheme == 'naked'){
    @if($actions_only == 'false'){
      // Active (default button active state)
      border-color:transparent !important;
      background-color:transparent !important;
      color: $text_color !important;
      text-decoration:underline;
      padding-left:0 !important;
      padding-right:0 !important;
    }
    &:hover{
      border-color:transparent !important;
      background-color:transparent !important;
      color: $highlight !important;
    }
    &:active,
    &:focus{
      border-color:transparent !important;
      background-color:transparent !important;
      color: $highlight !important;
    }
  }@else if( $color_scheme == 'active'){
    @if($actions_only == 'false'){
      // Active (default button active state)
      border-color:darken($grey_light, 5%) !important;
      background-color:darken($grey_light, 8%) !important;
      color: $text_color !important;
    }
    &:hover{
      background-color: $grey_light !important;
      border-color: $grey_light !important;
      color: $grey_dark !important;
    }
    &:active,
    &:focus{
      border-color:darken($grey_light, 5%) !important;
      background-color:darken($grey_light, 8%) !important;
      color: $text_color !important;
    }
  }@else{
    @if($actions_only == 'false'){
      border-color:$btn_default_border_color !important;
      background-color:$btn_default_bg_color !important;
      color: $btn_default_text_color !important;
    }
    &:hover{
      border-color:lighten($btn_default_border_color, 5%) !important;
      background-color:lighten($btn_default_bg_color, 5%) !important;
      color: $btn_default_text_color !important;
    }
    &:active,
    &:focus{
      border-color:darken($btn_default_border_color, 5%) !important;
      background-color:darken($btn_default_bg_color, 5%) !important;
      color: $btn_default_text_color !important;
    }
  }
}

/* Image Buttons (completely seperate button style) */
@mixin btn-image {
  text-indent:-9000px;
  display:inline-block;
  background-repeat:no-repeat;
  background-image:url(images/btn_sprite.png);
  background-color:transparent;
  border:0 none;
  outline:0 none;
  height:40px;
  width:154px; // fallback
}

// Undo Buttons
@mixin undo-button {
  font-size:$font_size;
  color:$text_color;
  background:none;
  border:0 none;
  padding:0;
  display:inline;
  &:hover{
    text-decoration:underline;
  }
}
