/* Holds helper mixins (extends compass functionality) */

// ***********************************************
// Markup free clearing
// ***********************************************
@mixin clearfix {
  overflow: hidden;   // New formatting context in better browsers
  *overflow: visible; // Protect IE7 and older from the overflow property
  *zoom: 1;           // Give IE hasLayout, a new formatting context equivalent
}

@mixin clearfix-after {
  &::after{
    clear:both;
    content:" ";
    display:table;
    visibility:hidden;
  }
}

// ***********************************************
// Grid
// ***********************************************
@mixin webksgrid ($number_of_cols: 2, $type_of_col: 'normal', $mobile_desktop_thin: 'desktop', $outer_inner_grid: 'inner'){
  // Deprecated
  $neighbor_columns_grid_rest: null;
  $available_grid_per_column: null;
  $number_of_grid_spaces: $number_of_cols - 1;
  $neighbor_columns_gridwidth: null;
  $summed_grid: null;
  
  @if $mobile_desktop_thin == 'mobile'{
    // Mobile
    $summed_grid: $mobile_grid * $number_of_grid_spaces;
    // -- Pro Grid zur verfuegung stehende Gridbreite errechnen
    $available_grid_per_column: $summed_grid / $number_of_cols;
    // -- Fuer zu :first und :last angrenzende Spalten, Rest zum tatsaechlichen Gridbreite ermitteln
    $neighbor_columns_grid_rest: $mobile_grid - $available_grid_per_column;
  }@else if $mobile_desktop_thin == 'thin'{
    // Thin
    $summed_grid: $thin_grid * $number_of_grid_spaces;
    // -- Pro Grid zur verfuegung stehende Gridbreite errechnen
    $available_grid_per_column: $summed_grid / $number_of_cols;
    // -- Fuer zu :first und :last angrenzende Spalten, Rest zum tatsaechlichen Gridbreite ermitteln
    $neighbor_columns_grid_rest: $thin_grid - $available_grid_per_column;
  }@else{
    // Default: Desktop
    $summed_grid: $grid * $number_of_grid_spaces;
    // -- Pro Grid zur verfuegung stehende Gridbreite errechnen
    $available_grid_per_column: $summed_grid / $number_of_cols;
    // -- Fuer zu :first und :last angrenzende Spalten, Rest zum tatsaechlichen Gridbreite ermitteln
    $neighbor_columns_grid_rest: $grid - $available_grid_per_column;
  }
  // -- Breite Gridbreite fuer Normalfaelle errechnen (nicht an :first und :last angrenzend)
  $middle_columns_gridwidth: $available_grid_per_column / 2;
  $neighbor_columns_gridwidth: $available_grid_per_column - $neighbor_columns_grid_rest;
  
  // Return
  @if $type_of_col == 'first'{
    clear:left;
    @if $outer_inner_grid == 'outer'{
      margin-right: $available_grid_per_column;
      margin-left:0;
    }@else if $outer_inner_grid == 'inner'{
      padding-right: $available_grid_per_column;
      padding-left:0;
    }
  }@else if $type_of_col == 'next-to-first'{
    @if $outer_inner_grid == 'outer'{
      margin-left: $neighbor_columns_grid_rest;
      margin-right: $neighbor_columns_gridwidth;
    }@else if $outer_inner_grid == 'inner'{
      padding-left: $neighbor_columns_grid_rest;
      padding-right: $neighbor_columns_gridwidth;
    }
  }@else if $type_of_col == 'last'{
    @if $outer_inner_grid == 'outer'{
      margin-left: $available_grid_per_column;
      margin-right: 0;
    }@else if $outer_inner_grid == 'inner'{
      padding-left: $available_grid_per_column;
      padding-right: 0;
    }
  }@else if $type_of_col == 'next-to-last'{
    @if $outer_inner_grid == 'outer'{
      margin-left: $neighbor_columns_gridwidth;
      margin-right: $neighbor_columns_grid_rest;
    }@else if $outer_inner_grid == 'inner'{
      padding-left: $neighbor_columns_gridwidth;
      padding-right: $neighbor_columns_grid_rest;
    }
  }@else if $type_of_col == 'normal'{
    @if $outer_inner_grid == 'outer'{
      margin-left: $middle_columns_gridwidth;
      margin-right: $middle_columns_gridwidth;
    }@else if $outer_inner_grid == 'inner'{
      padding-left: $middle_columns_gridwidth;
      padding-right: $middle_columns_gridwidth;
    }
  }
}

// ***********************************************
// Menus
// ***********************************************
@mixin horizontal-menu($withcutlines: null){
  width:auto;
  @include clearfix-after;
  > li{
    display:block;
    float:left;
    @if($withcutlines != null){
      border-width:0 1px 0 0;
      border-style:solid;
      border-color:$grey_light;
      &.last{ border-right:0; }
    }
  }
}

@mixin vertical-menu($withcutlines: null){
  // Only use case for this, is overriding a horizontal menu
  // A menu without styling allready is a vertical menu!
  > li{
    display:block;
    @if($withcutlines != null){
      border-width:0 0 1px 0;
      &.last{ border-bottom:0; }
    }
  }
}

// ***********************************************
// Simple Linear Gradients
// ***********************************************
@mixin vertical-gradient($start: #000, $stop: #FFF) { 
    background: $start; /* Old browsers */
    background: -moz-linear-gradient(top, $start 0%, $stop 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start), color-stop(100%,$stop)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $start 0%,$stop 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $start 0%,$stop 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $start 0%,$stop 100%); /* IE10+ */
    background: linear-gradient(to bottom, $start 0%,$stop 100%); /* W3C */
    //TODO prepros error filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{!start}', endColorstr='#{!stop}',GradientType=0 ); /* IE6-9 */
}
@mixin horizontal-gradient($start: #000, $stop: #FFF) { 
    background: $start; /* Old browsers */
    background: -moz-linear-gradient(left, $start 0%, $stop 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start), color-stop(100%,$stop)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, $start 0%,$stop 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, $start 0%,$stop 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, $start 0%,$stop 100%); /* IE10+ */
    background: linear-gradient(to right, $start 0%,$stop 100%); /* W3C */
    //TODO prepros error filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{!start}', endColorstr='#{!stop}',GradientType=0 ); /* IE6-9 */
}

//
// element-invisible improved and more robust
//
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax
  }
  clip: rect(1px, 1px, 1px, 1px);
}

//
// Turns off the element-invisible effect
//
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// ***********************************************
// Animation & Keyframe Mixin
// ***********************************************

// Animation Mixin (compass fails)
// #webks-tf: Seem to not work properly, we may dont need -moz- and -o anymore

@mixin animation($value) {
	-webkit-animation: unquote($value);
	   -moz-animation: unquote($value);
	     -o-animation: unquote($value);
	        animation: unquote($value);
}
 
@mixin animation-property($property, $value) {
	-webkit-animation-#{$property}: unquote($value);
	   -moz-animation-#{$property}: unquote($value);
	     -o-animation-#{$property}: unquote($value);
	        animation-#{$property}: unquote($value);
}
 
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} { @content; }
	   @-moz-keyframes #{$name} { @content; }
	     @-o-keyframes #{$name} { @content; }
	        @keyframes #{$name} { @content; }
}


// Transition Duration Mixin (compass mixin currently not available)
@mixin transition-duration($duration) {
  transition-duration:$duration;
  -moz-transition-duration:$duration;
  -webkit-transition-duration:$duration;
  -ms-transition-duration:$duration;
}

// ***********************************************
// Other Stuff
// ***********************************************

// Box Sizing Mixin
@mixin box-sizing($box_sizing) {
  box-sizing:$box_sizing;
  -moz-box-sizing:$box_sizing;
  -webkit-box-sizing:$box_sizing;
  -ms-box-sizing:$box_sizing;
}

// Text Overflow Ellipsis
@mixin text-overflow-ellipsis{
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
  display:block;
}