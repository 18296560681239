/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/*
 * @file
 * global.base
 *
 * - normalize with small modifications for Drupal and AT: http://necolas.github.com/normalize.css/
 * - grid widths, see $grid in _custom.scss
 * - Default container alignment.
 * - Flexible images and media declarations.
 * - Copies of the SCSS mixins for reference, which includes an improved
 *   element-invisible and focusable styles.
 */
/* =============================================================================
    HTML5 display definitions
   ========================================================================== */
/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

*::before, *::after {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

iframe {
  border: 0; }

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none; }

/* Touch Color */
html {
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1); }

/* =============================================================================
    Base
   ========================================================================== */
/*
 * If you are using the themes Responsive JS feature (media_queries.js) do not
 * set a font-family on the html element, use body.
 *
 * 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */
html {
  height: 100%;
  overflow-y: scroll; }

/*
 * 1 . Addresses margins handled incorrectly in IE6/7
 */
body {
  min-height: 100%;
  margin: 0;
  padding: 0; }

/*
 * Addresses font-family inconsistency between 'textarea' and other form elements.
 */
button,
input,
select,
textarea {
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif; }

/* =============================================================================
    Links
   ========================================================================== */
/* Links */
a {
  color: #484848;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  /* This sucks at firefox..
  &:visited{
    color:$link_color_visited;
} */ }
  a:active, a .active {
    outline: 0;
    color: #0e4dac;
    background-color: transparent; }
  a:hover, a:focus {
    color: #0e4dac;
    text-decoration: underline;
    outline: 0; }

/* =============================================================================
    Typography
   ========================================================================== */
/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted; }

blockquote {
  margin: 1em 40px; }

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic; }

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000; }

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
q {
  quotes: none; }

q:before,
q:after {
  content: '';
  content: none; }

/* =============================================================================
    Lists
   ========================================================================== */
/*
 * Addresses margins set differently in IE6/7
 */
dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

/*
 * Addresses paddings set differently in IE6/7
 */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/*
 * Corrects list images handled incorrectly in IE7
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

/* =============================================================================
    Embedded content
   ========================================================================== */
/*
 * 1. Remove border when inside 'a' element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */
img {
  -ms-interpolation-mode: bicubic;
  width: auto;
  height: auto; }

a img {
  border: 0 none; }

/*
 * Corrects overflow displayed oddly in IE9
 */
svg:not(:root) {
  overflow: hidden; }

/* =============================================================================
    Figures
   ========================================================================== */
/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */
figure {
  margin: 0; }

/* =============================================================================
    Forms
   ========================================================================== */
/*
 * Corrects margin displayed oddly in IE6/7
 */
form {
  margin: 0; }

/*
 * Define consistent margin, and padding
 */
fieldset {
  margin: 0;
  padding: 0.35em 0.625em 0.75em; }

/*
 * 1. Corrects color not being inherited in IE6/7/8/9
 * 2. Corrects text not wrapping in FF3
 * 3. Corrects alignment displayed oddly in IE6/7
 */
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/*
 * Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
 */
button,
input {
  line-height: normal; }

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

/*
 * Re-set default cursor for disabled elements
 */
button[disabled],
input[disabled] {
  cursor: default; }

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
 *    Known issue: excess padding remains in IE6
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type="search"] {
  box-sizing: border-box; }

/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  vertical-align: top; }

/* Placeholder */
::-webkit-input-placeholder {
  color: #959ba4; }

:-moz-placeholder {
  color: #959ba4; }

::-moz-placeholder {
  color: #959ba4; }

:-ms-input-placeholder {
  color: #959ba4; }

/* =============================================================================
    Tables
   ========================================================================== */
caption {
  color: #484848; }

/* =============================================================================
    Flexible Media and Cross browser improvements
   ========================================================================== */
/*
 * - This can cause issues in table cells where no width is set on the column,
 *   only in some versions of webkit and IE 7/8.
 * - declaring the width attribute on image elements and using max-width causes
 *   the "dissappering images" bug in IE8.
 * - Mapping services such as Google Maps may break also, see below for fixes.
 */
img {
  height: auto;
  -ms-interpolation-mode: bicubic; }

/* Ethan Marcotte - http://front.ie/l8rJaA */
img,
embed,
object,
video {
  max-width: 100%; }

/* Disable flexiblity for IE8 and below */
.lt-ie9 img,
.lt-ie9 object,
.lt-ie9 embed,
.lt-ie9 video {
  max-width: none; }

/* Override max-width 100% for map displays */
#map img,
.gmap img,
.view-gmap img,
.openlayers-map img,
#getlocations_map_canvas img,
#locationmap_map img,
.geofieldMap img,
.views_horizontal_slider img {
  max-width: none !important; }

/* =============================================================================
    Misc helpers, Accessibility classes etc
   ========================================================================== */
/* Prevent overflowing content */
header[role=banner],
.content-inner,
.nav,
.region-sidebar-first,
.region-sidebar-second,
.region-secondary-content,
.region-tertiary-content,
.region-footer {
  overflow: visible;
  word-wrap: break-word; }

/**
 * General classes for hiding content. These are all included as SASS mixins.
 * @SEE: sass/_custom.scss
 */
/**
 * Image replacement
 * Kellum Method: http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
 * Additional helpers from http://html5boilerplate.com/docs/css/
 */
.ir {
  /* Kellum Method */
  display: block !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  /* Remove the default border from elements like button */
  border: 0;
  /* Remove any text shadows */
  text-shadow: none;
  /* Hide any residual text in Safari 4 and any mobile devices that may need it */
  color: transparent;
  /* Hide the default background color on elements like button */
  background-color: transparent; }

/* More robust element-invisible and element-focuable classes */
.element-invisible {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto; }

/* Shift content offscreen, can be usefull when you reall need to do this */
.offscreen {
  position: absolute;
  top: -99999em;
  width: 1px;
  height: 1px;
  overflow: hidden;
  outline: 0; }

/* Hide content from all users */
.element-hidden {
  display: none; }

/* WYSIWYG RESET (eg. body background)*/
body#tinymce {
  background: #fff; }
