/* Holds reusable objects for global use */

// Functions
//recursive map-has-key() function
@function rmhk($m, $a){
  @if type-of($m) == map {
    @if map-has-key($m, $a) { @return true; };
    @each $key,$e in $m {
      @if rmhk($e, $a) { @return true; }
    }
  }
  @return false;
}

// Mixins
// *-mod means: It´s a modifier mixin, wich for eg. only change the color of a button.

// ***********************************************
// Icons
// ***********************************************

$icon_list: (
  search: "\eb36", // Search
  // zoom-in: "\7a", // Zoom
  // zoom-out: "\5a", // Zoom-Out
  check:"\ed72", // Check
  circle-check:"\ee76", // Circled Check
  remove:"\ed6e", // Remove (x)
  circle-remove:"\ed66", // Circled Remove (x)
  plus:"\ed5d", // Plus
  circle-plus:"\ed61", // Circled Plus
  minus:"\ed5e", // Minus
  circle-minus:"\ed62", // Circled Minus
  exclamation:"\f12c", // Exclamation Mark (Ausrufezeichen)
  circle-exclamation:"\ed52", // Circled Exclamation Mark (Ausrufezeichen)
  info:"\f12b", // info
  circle-info:"\ed64", // Circled info
  menu:"\ec71", // Menu
  // process:"\4d", // Cogs (Zahnraeder)
  print:"\ea68", // Printer
  phone:"\ea1d", // Phone
  mail:"\ea30", // Letter
  form:"\e922", // Pen
  user:"\eaf7", // User
  user-group:"\eb0c", // User Group
  user-add:"\eaf9", // Add User
  cart:"\e9fe", // Shopping Cart

  arrow-top:"\edd4", // Arrow Top
  arrow-right:"\edd6", // Arrow Right
  arrow-bottom:"\edd8", // Arrow Bottom
  arrow-left:"\edda", // Arrow Left

  arrow-thin-top:"\edba", // Arrow thin Top
  arrow-thin-right:"\edbe", // Arrow thin Right
  arrow-thin-bottom:"\edc2", // Arrow thin Bottom
  arrow-thin-left:"\edc6", // Arrow thin Left

  circle-arrow-top:"\ee2b", // Circled Arrow Top
  circle-arrow-right:"\ee2c", // Circled Arrow Right
  circle-arrow-bottom:"\ee2d", // Circled Arrow Bottom
  circle-arrow-left:"\ee2e", // Circled Arrow Left

  double-arrow-top:"\e902", // Double Arrow Top
  double-arrow-down:"\e900", // Double Arrow Down
  locked:"\eb50", // Lock (closed)
  key:"\eb4b",
  reset:"\eaab",
  // unlocked:"\75", // Lock (open)
  home:"\e901", // Home
  clock:"\ea4d", // Clock / Time
  calendar:"\ea5f", // calendar

  twitter:"\eef5",
  facebook:"\eeef",
  gplus:"\eeea",
  youtube:"\eefc",

  ticket:"\e9f9",
  theater:"\e9fb",
  store:"\e9fc",
  map:"\ea47",
  alarm:"\eb75",
  briefcase:"\ec02",
  road:"\ec11",
  furniture:"\ecde",
  mortarboard:"\e904",
  aidkit:"\eb71",
  city:"\e90a",
  soccer:"\e903",
  kids:"\e905",
  images:"\e940",
  meal:"\ebbc",
  misc:"\ec6a",
  wir-in-luebbecke:"\e906",
);

@mixin ico($icon_key: null, $icon_color: null, $icon_size: null){
  // Default inline icon
  &::before{
    font-family:$iconfont;
    font-weight:normal !important;
    font-style:normal !important;
    margin-right:4px;
    @if($icon_key != null){
      content: map-get($icon_list, $icon_key);
    }
    @if($icon_color != null){
      color:$icon_color;
    }
    @if($icon_size != null){
      font-size:$icon_size;
    }
  }
}
@mixin ico-replacement($icon_key: null, $icon_color: null, $icon_size: null, $icon_wrapper_width: null){
  // Replaces the elements text with an icon
  text-indent:-9000px;
  text-align:left;
  display:inline-block;
  float:left; // we changed this a serveral times.. inside buttons we NEED to use float or something. If this cause problems inside other elements - we need to do this specific for .btn. Otherwhise the button will get height as the item, which causes non equal height buttons.
  margin-right:4px;
  font-size:0; // fixing a bug on the android stack browser if theres text inside the icon element
  @if($icon_wrapper_width != null){
    width:$icon_wrapper_width;
  }@else{
    width:16px; // Fallback width - default icon size
  }
  &::before{
    text-indent:0;
    font-family:$iconfont;
    float:right;
    text-align:center;
    width:100%;
    @if($icon_key != null){
      content: map-get($icon_list, $icon_key);
    }
    @if($icon_color != null){
      color:$icon_color;
    }
    @if($icon_size != null){
      font-size:$icon_size;
    }@else{
      font-size:$icon_size_normal;
    }
  }
  @include clearfix-after;
}

@mixin get-ico($icon_key: null){
  // Gets only the icon letter (for custom implementation)
  @if( rmhk($icon_list, $icon_key) == true ) {
    &::before{
      font-family:$iconfont;
      content: map-get($icon_list, $icon_key);
    }
  }@else{
    @warn "Icon not found!";
  }
}

@mixin get-ico-only($icon_key: null){
  // Gets only the icon letter (for custom implementation)
  @if( rmhk($icon_list, $icon_key) == true ) {
    content: map-get($icon_list, $icon_key);
  }@else{
    @warn "Icon not found!";
  }
}

/* =============================================================================
 *   Navigation
 * ========================================================================== */

/* Tiny Menu */
@mixin tiny-menu($menu_direction: null){
  li{
    @if($menu_direction == "horizontal"){
      float:none;
      display:inline-block;
      margin-right:$thin_grid;
      &:last-child{
        margin-right:0;
      }
    }
    a{
      padding:2px 0;
    }
  }
}

/* =============================================================================
 *   Box Styles
 * ========================================================================== */

@mixin box-style($color_scheme: default){
  border-width:1px;
  border-style:solid;
  padding:$grid_vertical $grid;
  // Color
  @include box-style-mod($color_scheme);
  @include clearfix;
}

// Box Style Modifier
@mixin box-style-mod($color_scheme){
  // Adds only the new colors, not the entire box code
  @if($color_scheme == info){
    position:relative;
    font-size:$font_small;
    padding-left:60px;
    line-height:1.1em;
    border-color:darken($info_color, 8%);
    text-shadow:1px 1px 1px darken($info_color, 8%);
    background-color:$info_color;
    color:$info_text_color;
    cursor:help;
    a{
      color:$info_text_color;
    }
    &::before{
      @include get-ico-only('info');
      text-shadow:none;
      font-family:$iconfont;
      background-color:white;
      height:30px;
      width:30px;
      border-radius:50%;
      background-color:white;
      text-align:center;
      line-height:30px;
      vertical-align:middle;
      position:absolute;
      left:15px;
      top:13px;
      font-size:22px;
    }
  }@else{
    // Default
    border-color:$grey_light;
    border-radius:0;
    background-color:white;
  }
}

/* =============================================================================
 *   Messages
 * ========================================================================== */

@mixin message($color_scheme: default){
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: $message_border_radius;
  position:relative;
  &::before{
    font-family:$iconfont;
    position:absolute;
    left:15px;
    top:14px;
    font-size:25px;
    line-height:normal;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  @include message-mod($color_scheme);
}
@mixin message-mod($color_scheme){
  @if($color_scheme == info){
    color: $message_info_text;
    background-color: $message_info_background;
    border-color: $message_info_border;
    a{ color:$message_info_text; }
    &::before{
      @include get-ico-only('circle-info');
    }
  }@else if($color_scheme == success){
    color: $message_success_text;
    background-color: $message_success_background;
    border-color: $message_success_border;
    a{ color:$message_success_text; }
    &::before{
      @include get-ico-only('circle-check');
    }
  }@else if($color_scheme == warning){
    color: $message_warning_text;
    background-color: $message_warning_background;
    border-color: $message_warning_border;
    a{ color:$message_warning_text; }
    &::before{
      @include get-ico-only('circle-exclamation');
    }
  }@else if($color_scheme == error){
    color: $message_error_text;
    background-color: $message_error_background;
    border-color: $message_error_border;
    a{ color:$message_error_text; }
    &::before{
      @include get-ico-only('circle-remove');
    }
  }@else{
    color: $text_color;
    background-color: white;
    border-color: $grey_light;
    a{ color:$text_color; }
    &::before{
      @include get-ico-only('circle-info');
    }
  }
}


// ***********************************************
// Badges
// ***********************************************
@mixin badge($color_scheme: 'default', $icon: null){
  display: inline-block;
  font-size: $font_small;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius:$border_radius_small;
  padding:3px 5px;

  // Icons
  &::before{
    display:inline-block;
    margin-right:3px;
  }

  // Color
  @if($color_scheme != null){
    @include badge-mod($color_scheme);
  }
}

// Badge Modifier
@mixin badge-mod($color_scheme){
  @if($color_scheme == 'red'){
    background-color:$red !important;
    color:white !important;
  }@else if( $color_scheme == 'green'){
    background-color:$green !important;
    color:white !important;
  }@else if( $color_scheme == 'grey'){
    background-color: $grey_light !important;
    color:$grey !important;
  }@else{
    background-color:$highlight !important;
    color: $highlight_text_color !important;
  }
}


// ***********************************************
// Misc
// ***********************************************

/* Flyout */
@mixin flyout($padding: $mobile_grid, $direction: right){
  background:white;
  color:$text_color;
  padding:$padding;
  box-shadow:$shadow_flyout;
  min-width:260px;
  z-index:999;
  border:0 none;

  position:absolute;
  top:100%;
  @if($direction == left){
    right:0;
  }@else{
    left:0;
  }
}

/* Overlay */
@mixin overlay{
  display:none;
  position:absolute;
  top:0;
  left:0;
  z-index:9998;
  height:100%;
  width:100%; /* overriden by jquery */
  background:rgba(255,255,255,0.45);
  filter: blur(5px);
}

/* Cutline */
@mixin cutline($direction: bottom, $spacing_one: $space_thin_v, $spacing_two: $space_thin_v){
  @if($direction == top){
    box-shadow:0 -1px 0 $grey_light;
    padding-top:$spacing_one;
    margin-top:$spacing_two;
  }@else if($direction == left){
    box-shadow:-1px 0 0 $grey_light;
    padding-left:$spacing_one;
    margin-left:$spacing_two;
  }@else if($direction == right){
    box-shadow:1px 0 0 $grey_light;
    padding-right:$spacing_one;
    margin-right:$spacing_two;
  }@else{
    box-shadow:0 1px 0 $grey_light;
    padding-bottom:$spacing_one;
    margin-bottom:$spacing_two;
  }
}
@mixin bold-cutline($direction: bottom, $spacing_one: $grid_vertical, $spacing_two: $grid_vertical){
  @if($direction == top){
    border-top:8px solid $grey_light;
    padding-top:$spacing_one;
    margin-top:$spacing_two;
  }@else if($direction == left){
    border-right:8px solid $grey_light;
    padding-right:$spacing_one;
    margin-right:$spacing_two;
  }@else if($direction == right){
    border-left:8px solid $grey_light;
    padding-left:$spacing_one;
    margin-left:$spacing_two;
  }@else{
    border-bottom:8px solid $grey_light;
    padding-bottom:$spacing_one;
    margin-bottom:$spacing_two;
  }
}

/* Section Titles */
@mixin section-title(){
  padding-bottom:$space_thin_v / 2;
  border-bottom:2px solid $bluish_grey_light;
  margin-bottom:$space_thin_v;

  font-size:$h2_font_size;
  line-height:$h2_line_height;
  font-weight:$h2_font_weight;
  font-family:$h2_font_family;
}
